<template>
  <div class="page-index">
    <div class="hd">
      <div class="info">
        <div class="info-hd">付款给</div>
        <div class="info-bd">福贡县人民医院</div>
      </div>
      <div class="info-icon">
        <!-- <img class="info-icon-img" src="../../../assets/info-icon.png" alt=""> -->
        <img class="info-icon-img" :src="MDlogo" alt="" />
      </div>
      <div class="bg"></div>
    </div>
    <div class="bd">
      <div class="box">
        <div class="box-hd">
          <div class="box-hd-label">费用总额</div>
          <div class="box-hd-value">{{ mPayShow.feeSumamt }}元</div>
        </div>
        <div class="box-bd">
          <div class="box-item">
            <div class="box-bd-label">医保基金支付</div>
            <div class="box-bd-value">{{ mPayShow.fundPay }}元</div>
          </div>
          <div class="box-item">
            <div class="box-bd-label">个人帐户支付</div>
            <div class="box-bd-value">{{ mPayShow.psnAcctPay }}元</div>
          </div>
          <div class="box-item">
            <div class="box-bd-label">其他抵扣金额</div>
            <div class="box-bd-value">{{ mPayShow.othFeeAmt }}元</div>
          </div>
        </div>
        <div class="box-ft">
          <div class="box-ft-label">现金支付</div>
          <div class="box-ft-value">{{ mPayShow.ownPayAmt }}元</div>
        </div>
        <div class="box-append" @click="handleActionsheet">
          查看明细
        </div>
      </div>
      <div class="bd-append">
        <i class="bd-append-icon"></i>
        <div class="bd-append-text">医保移动支付</div>
      </div>
    </div>
    <div class="ft">
      <div class="pay">
        <div class="pay-label">您还需支付：</div>
        <div class="pay-value">¥{{ mPayShow.ownPayAmt }}</div>
      </div>
      <div class="btn" :disabled="isclick1" @click="medicarePay">去支付</div>
    </div>
    <div class="actionsheet" v-show="actionsheetVisible">
      <div class="actionsheet-mask" @click="handleActionsheet"></div>
      <div class="actionsheet-panel">
        <div class="actionsheet-hd">
          <div class="actionsheet-hd-tt">处方明细</div>
          <i class="actionsheet-icon-close" @click="handleActionsheet"></i>
        </div>
        <div class="actionsheet-bd">
          <div class="actionsheet-box">
            <div class="actionsheet-box-hd">就诊信息</div>
            <div class="actionsheet-box-bd">
              <van-cell-group>
                <van-cell title="就诊人" :value="userName" />
                <van-cell title="科室名称" :value="lists.deptName" />
                <van-cell title="开立医生" :value="lists.drName" />
                <van-cell title="开立时间" :value="lists.operTime" />
                <van-cell
                  title="费用总额"
                  style="font-size:18px;font-weight:1000;"
                >
                  <template #default>
                    <div>
                      <div class="actionsheet-box-item-value em">
                        {{ feeTotal }}元
                      </div>
                    </div>
                  </template>
                </van-cell>
              </van-cell-group>

              <!-- <div
                class="actionsheet-box-item">
                <div class="actionsheet-box-item-label-wrap">
                  <div class="actionsheet-box-item-label">{{lists.userName}}</div>
                  <div class="actionsheet-box-item-sublabel">{{lists.userName}}</div>
                </div>
                <div 
                  class="actionsheet-box-item-value em"
                  >{{this.feeTotal}}</div>
              </div> -->
            </div>
            <div class="actionsheet-box-hd">费用信息</div>
            <div class="actionsheet-box-bd">
              <van-cell-group>
                <template v-if="list.length > 0">
                  <van-cell v-for="Ditem in list" :key="Ditem + 'OrdIn'">
                    <template slot="title">
                      <span style="color: #969799">{{ Ditem.payName }}</span>
                      <span style="float:right">{{ Ditem.totalCost }}元</span>
                    </template>
                    <template slot="label">
                      <div
                        v-for="(item, index) in Ditem.clinicDetails"
                        :key="index"
                      >
                        <span>{{ item.itemName }}</span>
                        <span style="float:right">{{ item.totalCost }}元</span>
                      </div>
                    </template>
                  </van-cell>
                </template>
              </van-cell-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ajax from "../../../lib/ajax";
import store from "../../../store";
import { Toast, Dialog } from "vant";
// const OperatorId = "0733";
export default {
  name: "PageIndex",
  data() {
    return {
      isclick1: false,
      mPayL: {},
      mPayShow: {},
      angents: {},
      wxopenid: "",
      idCard: "",
      userName: "",
      cardNo: "",
      lists: [],
      list: [],
      wxMPayInfo: null,

      regNo: "",
      authCode: "",
      feeTotal: "",
      MDlogo: require("../../../assets/FGlogo.png"),
      payArg: [],
      prescriptionList: [
        {
          title: "就诊信息",
          list: [
            { label: "门诊类别", value: "门(急)诊" },
            { label: "门诊科室", value: "普通内科" },
            { label: "医生姓名", value: "张三" },
            { label: "处方时间", value: "2021/06/08 14:54:00" },
            { label: "费用总额", value: "368.50元", highlight: true },
          ],
        },
        {
          title: "诊断信息",
          list: [
            { label: "诊断名称", value: "外伤肿胀" },
            { label: "诊断编号", value: "E3D.25" },
          ],
        },
        // {
        //   title: '特殊信息',
        //   list: [
        //     { label: '病情名称', value: '高血压' },
        //     { label: '病情编号', value: '2220003495858' },
        //   ]
        // },
        {
          title: "费用信息",
          list: [],
        },
        // {
        //   title: '其他抵扣金额',
        //   list: [
        //     { label: '住院押金抵扣', value: '50元' },
        //     { label: '医院负担金额抵扣', value: '50元' },
        //   ]
        // },
      ],
      actionsheetVisible: false,
      recipes: [], //预结算接口入参
      agentList: [], //代理接口入参
    };
  },
  mounted() {
    this.wxopenid = store.getters["wechat/openid"];
    this.idCard = localStorage.getItem("idCard");
    this.userName = localStorage.getItem("userName");
    this.cardNo = localStorage.getItem("cardNo");
    // //
    let retCode = "";
    // this.regNo = this.$route.query.regNo;
    // console.log(44444, this.regNo);
    if (this.$route.query.retCode >= 0) {
      retCode = this.$route.query.retCode;
      if (retCode == 150403) {
        Dialog.alert({
          title: "提示",
          message: "您已取消电子医保授权！",
        }).then(() => {
          // this.$router
          //   .replace({
          //     //path:'/DepS/:Idcard',
          //     path: "/MPayL",
          //   })
          window.history.go(-2)
            .catch((err) => {
              console.log(err);
            });
          // on close
        });
        return;
      }
    }
    this.authCode = this.$route.query.authCode;
    this.getPayAuth();
    // this.mPayL=store.getters["store/mPayL"];
    // console.log(this.mPayL);
    //  this.mPayShow=store.getters["store/mPayShow"];
    //  console.log(this.mPayShow)
    // this.angents=store.getters["store/angents"];
    // console.log(this.angents)
    // this.getPayAuth();
    //  this.wxopenid = store.getters["wechat/openid"];
    //   this.idCard = localStorage.getItem("idCard");
    // this.userName = localStorage.getItem("userName");
    // this.cardNo = localStorage.getItem("cardNo");
  },

  methods: {
    async onLoad() {
      let me = this;
      // 异步更新数据
      setTimeout(() => {
        for (let i = 0; i < 0; i++) {
          this.list.push(this.list.length);
        }
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length == this.list.length) {
          this.finished = true;
        }
      }, 500);
      // let regno = store.getters["store/regNo"];
      // let patientid = store.getters['store/patientid'];//声明病例号
      // console.log("挂号流水号"+regno);
      // console.log("病例号"+patientid);
      // let regNo = this.regNo; //store.getters["store/regNo"];
      // let idcard = store.getters["store/idcard"];
      // let seqNo = store.getters["store/seqNo"];
      // console.log(seqNo);
      // seqNo = "";
      // let patientid = store.getters["store/patientid"]; //声明病例号
      // let idcard = this.idCard;//store.getters["store/idcard"];
      console.log("打桩输出1111111111111111111");
      Toast.loading({
        message: "数据生成中，请稍后...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      console.log("打桩输出222222222222222222");

      let outpatientId = localStorage.getItem("userId");
      ajax
        .post("/Api/FeeService/GetFeeList", {
          userID: outpatientId,
          payStatus: 0,
          operatorId: "100002",
          payWay: 3,
          machineName: "wechat",
        })
        .then((rep) => {
          this.feeTotal = 0;
          // console.log(990,rep.data.result.clinicFees[0].recipes);
          me.list = rep.data.result.clinicFees;
          let repArr = [];
          let repsArr = rep.data.result.clinicFees[0].recipes;
          for (let i = 0; i < repsArr.length; i++) {
            repArr.push(repsArr[i].recipeNO);
          }
          // payArg处方号集合（数组类型）后面入参需要
          this.payArg = repArr;
          console.log("this.payArg:", this.payArg);
          console.log('me.list',me.list);
          let regNo=me.list[0].regNo
          this.regNo=me.list[0].regNo
          me.list = me.list.filter((item) => item.regNo == regNo);
          for (let i = 0; i < me.list.length; i++) {
            const el = me.list[i];
            this.feeTotal += el.totalFee;
          }
          this.lists = me.list[0];
          console.log('this.lists',this.lists);
          // for循环遍历得到两个数组，之后的接口入参用得到
          for (let i = 0; i < this.lists.recipes.length; i++) {
            let a = {
              totalCost: this.lists.recipes[i].totalCost,
              yllb: null,
              recipeNo: this.lists.recipes[i].recipeNO,
              ybzdbm: null,
              ybzdmc: null,
            };
            let b = {
              repNoList: this.lists.recipes[i].recipeNO,
              fee: this.lists.recipes[i].totalCost,
              siTransInfo: {
                siPatientInfo: null,
                siInputInfo: null,
                siOutput: null,
              },
            };
            //处方数组，包含每笔处方的处方号和金额 后面接口入参需要
            this.recipes.push(a);
            this.agentList.push(b);
          }
          console.log("处方数组this.recipes:", this.recipes);
          console.log("处方数组this.agentList:", this.agentList);

          console.log("this.lists:", this.lists);
          this.gotoPay(
            this.lists.deptName,
            this.lists.drName,
            this.lists.operTime,
            this.lists.recipes[0].clinicDetails[0].itemName,
            this.lists.totalFee,
            this.lists.drId,
            this.lists.recipes[0].recipeNO
          );

          // Toast.clear();
          // console.log(me.list);
          console.log(me.lists);
        })
        .catch(() => {
          Toast.clear();
          Toast("请求失败，请稍后重试！");
        });

      // await ajax
      //   .post("/Api/FeeService/GetFeeDetail", {
      //     regNo: regNo,
      //     seqNo: seqNo,
      //     idCard: "533025199707123317",
      //   })
      //   .then((rep) => {
      //     me.list = rep.data.result.data[0];
      //     console.log(me.list);
      //     console.log(me.list[0].operTime);
      //     me.list[0].operTime =
      //       me.list[0].operTime.substring(4, 6) +
      //       "/" +
      //       me.list[0].operTime.substring(6, 8) +
      //       "/" +
      //       me.list[0].operTime.substring(8, 10) +
      //       ":" +
      //       me.list[0].operTime.substring(10, 12) +
      //       ":" +
      //       me.list[0].operTime.substring(12, 14);
      //     // alert(me.list[0].operTime);
      //     me.lists = me.list[0].clinicDetails;
      //     console.log(me.lists);
      //   })
      //   .catch(() => {
      //     Toast("请求失败，请稍后重试！");
      //   });
    },
    handleActionsheet() {
      this.actionsheetVisible = !this.actionsheetVisible;
    },
    // 生成订单前往结算页面
    gotoPay(deptName, drName, operTime, itemName, sumfee, fid, recipeNO) {
      console.log(deptName, drName, itemName, sumfee, operTime, fid, recipeNO);
      let userId = localStorage.getItem("userId");
      // let openId=this.wxopenid;
      // let phone=localStorage.getItem("phone")
      // console.log(this.radio)
      // if(this.radio==''){
      //   Toast.fail({ message: "请选择是否使用个人账户支付" });
      //   //  console.log("不能为空")
      //   return;
      // }
      // let outpatientId = localStorage.getItem("userId");
      let hispre = {
        payAuthNo: this.wxMPayInfo.pay_auth_no,

        uldLatlnt: `${this.wxMPayInfo.user_longitude_latitude.longitude},${this.wxMPayInfo.user_longitude_latitude.latitude}`,

        totCost: this.feeTotal,
        cardno: userId,
        clinicNo: this.regNo,
        recipes: this.recipes,
      };
      console.log("入参hispre", hispre);
      ajax.post(`/Api/YBMPay/PreSiBalance`, hispre).then((res) => {
        console.log(res.data.success);
        console.log("/PreSiBalance返参res", res);
        if (res.data.success) {
          let oid = res.data.value.oid;

          let out6202 = res.data.value;
          this.mPayShow = out6202;
          let createPreq = {
            openid: this.wxopenid,
            payAuthNo: this.wxMPayInfo.pay_auth_no,
            payOrdId: out6202.payOrdId,
            oid: oid,
            hisRegNo: this.regNo,
            hisRecipeNos: this.payArg,
            total_fee: out6202.feeSumamt,
            cash_fee: out6202.ownPayAmt,
            insurance_fee: out6202.fundPay,
            psnAcctPay: out6202.psnAcctPay,
            body: deptName + "|" + drName + "|" + itemName + "|" + sumfee,
            return_url: `http://wx.fgxrmyy.cn/MPayS?regno=${this.regNo}`,
            pay_type: out6202.payType,//out6202.fundPay > 0 ? (out6202.ownPayAmt > 0 ? 3 : 2) : 1, 
            user_id: userId,
            user_card_no: this.idCard,
            user_name: this.userName,
            setlLatlnt: `${this.wxMPayInfo.user_longitude_latitude.longitude},${this.wxMPayInfo.user_longitude_latitude.latitude}`,
          };
          // store.commit("store/setMPayL",createPreq);
          this.mPayL = createPreq;

          ajax
            .post("/Api/WechatAgentService/AgentFeePaid2YBM", {
              orderId: oid,
              list: this.agentList,
              // [
              //   {
              //     repNoList: recipeNO,
              //     fee: this.feeTotal,
              //     siTransInfo: {
              //       siPatientInfo: null,
              //       siInputInfo: null,
              //       siOutput: null,
              //     },
              //   },
              // ],
              patientID: userId,
              registerNO: this.regNo,
              cardTypeID: 4,
              cardNO: this.idCard,
              payType: {
                payTypeID: 0,
                payModeName: null,
                openBank: null,
                payCardNo: null,
                posTransNo: null,
                amount: null,
              },
              operatorId: null,
              payWay: 0,
              machineName: null,
            })
            .then((res) => {
              if (res.data.result > 0) {
                console.log(666);
                this.isclick1 = true;
                Toast.clear();
              } else {
                Toast.clear();
                console.log("代理失败！");
                Toast("医保订单代理失败");
              }
            });
        } else {
          Toast.clear();
          console.log("费用上传失败！");
          Toast("医保费用上传失败");
        }
      });

},
    // 获取微信支付授权及是否本人核对
    getPayAuth() {
      console.log("this.authCode:", this.authCode);
      console.log("this.wxopenid:", this.wxopenid);

      ajax
        .post("/Api/WxYBPayServices/QueryUserInfo", {
          qrcode: this.authCode,
          openid: this.wxopenid,
        })
        .then((res) => {
          console.log(111111111111111111);
          console.log("打印", res);
          // 测试环境开启
          // this.onLoad();
          // 正式环境开启
          if (res.data.result.code == 0) {
            console.log(22222222222222222222222222);

            // if (res.data.result.user_name == this.userName) {
            this.wxMPayInfo = res.data.result;

            this.onLoad();
            // } else {
            //   // Toast("")
            //   Dialog.alert({
            //     title: "提示",
            //     message: "电子医保暂目前只支持个人医保支付",
            //   }).then(() => {
            //     this.$router
            //       .replace({
            //         //path:'/DepS/:Idcard',
            //         path: "/MPayL",
            //       })
            //       .catch((err) => {
            //         console.log(err);
            //       });
            //     // on close

            //     // on close
            //   });
            // }
          } else {
            // Toast("")
            // Toast("")
            Dialog.alert({
              title: "提示", 
              message: "电子医保支付授权失败",
            }).then(() => {
              // this.$router
              //   .replace({
              //     //path:'/DepS/:Idcard',
              //     path: "/MPayL",
              //   })
              window.history.go(-2)
                .catch((err) => {
                  console.log(err);
                });
              // on close
            });
          }

          // console.log(res)
        })
        .catch(() => {
          Toast("电子医保支付授权失败");
        });


    },
    // 确认支付
    medicarePay() {
      if (this.isclick1 == false) {
        return;
      }
      this.isclick1 = false;
      ajax.post("/Api/WxYBPayServices/CreatePay", this.mPayL).then((res) => {
        if (res.data.result.success == true) {
          console.log("/CreatePay接口成功");
          let payUrl = res.data.result.payUrl;
          window.location.href = payUrl;
        } else {
          Toast.clear();
          Toast("Createpay接口调用失败");
          this.isclick1 = true;
        }
      });

   },
  },
};
</script>

<style scoped>
@import "./index.css";
@import "./actionsheet.css";
</style>
